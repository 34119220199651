<template>
	<div
		class="tg-title-font justify-between h-[72px] items-center w-full fixed z-10 top-[30px] page-px hidden lg:flex bg-white"
	>
		<div class="flex items-center gap-1">
			<NuxtLink
				v-if="!plfm.isEditorial"
				to="/"
			>
				<img
					:src="logoUrl"
					:alt="platformName"
					class="h-6 mr-4"
				/>
			</NuxtLink>

			<NuxtLink
				to="/textiles"
				class="desktop-nav-item"
				@click="handleTextileLinkClick"
			>
				{{ $t("components.nav.products") }}
			</NuxtLink>
			<!-- <DesktopNavItemWithMenu
				:section="$t('components.nav.products')"
				:items="products"
			/> -->

			<NavItemWithMenu
				v-if="collections?.length"
				:section="$t('components.nav.collections')"
				:items="collections"
			/>

			<NuxtLink
				to="/merchants"
				class="desktop-nav-item"
				>{{ $t("components.nav.merchants") }}</NuxtLink
			>

			<NuxtLink
				v-if="blog_active"
				to="/blog"
				class="desktop-nav-item"
				>Blog</NuxtLink
			>
		</div>

		<NuxtLink
			v-if="plfm.isEditorial"
			class="absolute left-1/2 -translate-x-1/2"
			to="/"
		>
			<img
				:src="logoUrl"
				:alt="platformName"
				class="h-6"
			/>
		</NuxtLink>

		<div class="flex items-center gap-2">
			<!-- Hidden search -->
			<SearchInput
				v-if="false"
				model-value=""
				:placeholder="$t('components.nav.search_placeholder')"
				clearable
				class="lg:min-w-[115px] xl:min-w-[200px]"
			/>
			<AccountDropdown />

			<NuxtLink
				class="flex items-center gap-1 cursor-pointer"
				:to="{ name: 'my-cart' }"
			>
				<Icon
					name="md-local_mall"
					class="mr-1"
				/>
				<Badge
					v-if="numOfTextileItems !== null"
					:visible="true"
					:content="numOfTextileItems as number ?? 0"
					:color="numOfTextileItems as number > 0 ? 'red' : '#6E6E73'"
					class="-mt-[20px] mr-[7px]"
				/>
				<span class="translate-y-[2px]">Cart</span>
			</NuxtLink>
		</div>
	</div>
</template>

<script lang="ts" setup>
import NavItemWithMenu from "@/components/Nav/NavItemWithMenu.vue";
import AccountDropdown from "@/components/Nav/AccountDropdown.vue";

const { platformId, plfm, logoUrl, collections, platformName } = storeToRefs(usePlatformStore());
const { numOfTextileItems } = storeToRefs(useCartStore());
const { setProducts, setFilters, setOrder } = useCatalogStore();
const router = useRouter();

const { getListByPlatformArticle } = useECMApi();
const blog_active = ref(false);

const handleTextileLinkClick = () => {
	if (router.currentRoute?.value.name === "textiles") {
		setFilters({});
		setOrder("");
		setProducts(platformId.value);
	}
};
onMounted(async () => {
	const res = await getListByPlatformArticle(platformId.value);
	if (res.response?.data) {
		if (res.response.data.filter(article => article.publish_state === true).length > 0) {
			blog_active.value = true;
		}
	}
});
</script>
