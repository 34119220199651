<template>
	<div>
		<TopBanner />
		<NavbarDesktop />
		<NavbarMobile @open-drawer="isMobileDrawerOpen = true" />
		<v-navigation-drawer
			v-model="isMobileDrawerOpen"
			temporary
			fixed
			location="right"
			width="320"
		>
			<NavDrawer
				:is-mobile-drawer-open="isMobileDrawerOpen"
				@close-drawer="isMobileDrawerOpen = false"
			/>
		</v-navigation-drawer>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import type { Response } from "@tengiva/services-api-layer/types/response";
import TopBanner from "@/components/Nav/TopBanner.vue";
import NavbarDesktop from "@/components/Nav/NavbarDesktop.vue";
import NavbarMobile from "@/components/Nav/NavbarMobile.vue";
import NavDrawer from "@/components/Nav/NavDrawer.vue";
import type { Collection } from "@/types/products";

const { platformOrgId } = storeToRefs(usePlatformStore());
const isMobileDrawerOpen = ref(false);

const { listByOrganizationMarketplace } = usePMApi();
const { collections } = storeToRefs(usePlatformStore());
const { $sentry } = useNuxtApp();

const { t } = useI18n();

onMounted(async () => {
	// make error clearer if it is not defined, as an empty string returns that the param is missing
	const platformOrganizationId: any = platformOrgId.value || undefined;

	try {
		const { response, error } = (await listByOrganizationMarketplace(platformOrganizationId)) as Response<Collection[]>;

		if (error) throw error;
		if (!response?.data) return;

		const collectionsFromBe = response?.data as Collection[];
		const collectionsItems = collectionsFromBe.map(collection => ({
			key: collection.name.toLowerCase().replace(" ", "-"),
			name: collection.name,
			url: { name: "collections-slug", params: { slug: collection.slug } },
			isBold: false,
		}));

		if (collectionsItems?.length) {
			collections.value = [
				{
					key: "all-collections",
					name: t("components.nav.collections_submenu_all"),
					url: {
						name: "collections",
					},
					isBold: true,
				},
				...collectionsItems,
			];
		}
	} catch (err: any) {
		$sentry.captureException(`Cannot fetch collections for nav menu (${err.data?.error.message})`);
	}
});
</script>
